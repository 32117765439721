















import {
  ref,
  defineComponent,
  useRoute,
  ssrRef,
  useContext,
  useFetch,
} from '@nuxtjs/composition-api';
import { CacheTagPrefix, useCache } from '@gemini/vsf-cache';
import { useUrlResolver } from '~/composables/useUrlResolver';
import cacheControl from '~/helpers/cacheControl';
import ErrorComponent from '~/components/ErrorComponent.vue';
import { useContent } from '@gemini-vsf/composables';
import { LoadingDots } from '~/components/General';
import { useCms } from '~/composables';

interface RootComponent {
  name: string;
  props?: object;
}

function isRedirect(redirectCode: number | undefined): boolean {
  const redirectCodes = new Set().add(301).add(302);
  return redirectCodes.has(redirectCode);
}

export default defineComponent({
  name: 'RoutePage',
  components: {
    LoadingDots,
    ProductPage: () => import('~/pages/Product.vue'),
    CategoryPage: () => import('~/pages/Category.vue'),
    CmsPage: () => import('~/pages/Page.vue'),
    PayloadPage: () => import('~/pages/PayloadPage.vue'),
    ErrorComponent,
  },
  middleware: cacheControl({
    'max-age': 60,
    'stale-when-revalidate': 5,
  }),
  transition: 'fade',
  setup() {
    const CacheTagPrefixUrl = 'U'; // TODO move to cache lib
    const { error: nuxtError, redirect, app } = useContext();
    const route = useRoute();
    const { cmsData, getCmsPage } = useCms();
    const {
      params: { path },
      path: routePath,
    } = route.value;
    const {
      result,
      search: resolveUrl,
      loading: urlResolverLoading,
    } = useUrlResolver();
    const rootComponent = ssrRef<RootComponent | null>(null);
    const { addTags } = useCache();
    const { page: contentPage, loadContent } = useContent(`cmsPage-${path}`);
    const loadingPage = ref(false);

    // eslint-disable-next-line sonarjs/cognitive-complexity
    useFetch(async () => {
      loadingPage.value = true;
      rootComponent.value = { name: '' };
      await resolveUrl();

      const component: RootComponent = { name: '' };
      const urlRewrite = result?.value;
      const shouldRedirect = isRedirect(urlRewrite?.redirectCode);

      if (
        shouldRedirect &&
        urlRewrite?.urlPath &&
        urlRewrite?.urlPath !== path
      ) {
        const regx = /^(?:[a-z]+:)?\/\//i;
        const redirectPath = regx.test(urlRewrite.urlPath)
          ? urlRewrite.urlPath
          : app.localePath(`/${urlRewrite.urlPath.replace(/^(\/)/, '')}`);
        redirect(urlRewrite.redirectCode, redirectPath);
        loadingPage.value = false;
        return;
      }

      switch (urlRewrite?.entityCode) {
        case 'Product': {
          component.name = 'ProductPage';
          component.props = {
            productId: urlRewrite?.entityId,
          };
          break;
        }
        case 'ProductList': {
          component.name = 'CategoryPage';
          component.props = {
            categoryId: urlRewrite?.entityId,
          };
          break;
        }
        case null: {
          await getCmsPage(urlRewrite.entityId);
          if (
            cmsData?.value &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            cmsData?.value?.layout?.length > 0 &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            cmsData.value._status !== 'draft'
          ) {
            component.name = 'PayloadPage';
            component.props = {
              contentPage: cmsData?.value,
            };
          }
          break;
        }
        default: {
          // TODO define a specific case once implemented DXP cms service
          await loadContent({
            identifier: routePath.slice(1),
          });
          if (
            contentPage?.value?.content &&
            contentPage.value.title &&
            contentPage.value.content !== '<p></p>'
          ) {
            component.name = 'CmsPage';
            component.props = {
              identifier: path,
              content: contentPage.value,
            };
            break;
          } else {
            await loadContent({
              identifier: `default-${routePath.slice(
                Math.max(0, routePath.indexOf('-') + 1)
              )}`,
            });
            if (
              contentPage?.value?.content &&
              contentPage.value.title &&
              contentPage.value.content !== '<p></p>'
            ) {
              component.name = 'CmsPage';
              component.props = {
                identifier: path,
                content: contentPage.value,
              };
              break;
            }
          }
          component.name = '404';
          break;
        }
      }
      loadingPage.value = false;

      if (component.name !== '404') {
        addTags([
          { prefix: CacheTagPrefix.View, value: 'urlrewrite' },
          { prefix: CacheTagPrefixUrl, value: urlRewrite?.entityId },
        ]);
      }

      if (component.name === '404') {
        nuxtError({
          statusCode: 404,
        });
      }
      rootComponent.value = component;
    });

    return {
      rootComponent,
      loading: urlResolverLoading,
      result,
      contentPage,
      loadingPage,
    };
  },
});
