<template>
  <div id="error" class="error-page">
    <div class="error-page__title">
      {{ $t('oops!') }}
    </div>
    <div class="error-page__title">
      {{ $t('The page you were looking for cannot be found') }}
    </div>
    <div class="error-page__subtitle">
      {{
        $t(
          'If you typed the URL directly, please make sure the spelling is correct. If you clicked on a link to get here, the link is outdated.'
        )
      }}
    </div>
    <div class="error-page__subtitle">
      {{
        $t('You can also click the following links to get you back on track!')
      }}
    </div>
    <div class="error-page__actions">
      <CustomCta theme="dark" :link="localePath('/')">
        {{ $t('Homepage') }}
      </CustomCta>
      <CustomCta theme="dark" :link="localePath('/contact')">
        {{ $t('Contact us') }}
      </CustomCta>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { CustomCta } from '~/components/General';

export default defineComponent({
  name: 'ErrorComponent',
  components: {
    CustomCta,
  },
});
</script>

<style lang="scss" scoped>
.error-page {
  text-align: center;
  margin: 1.875rem auto 0;
  padding: 0 1.5rem 3.125rem 1.5rem;
  max-width: 64rem;
  &__title {
    @include mobile-h3;
    padding-bottom: 1.25rem;
  }
  &__subtitle {
    padding-bottom: 1.25rem;
  }
  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.625rem;
  }
  @include from-desktop-min {
    padding: 0 5.25rem 8.4375rem 5.25rem;
    &__title {
      @include desktop-h3;
      padding-bottom: 2.5rem;
    }
    &__actions {
      flex-direction: row;
      justify-content: center;
    }
  }
}
</style>
